<template>
  <div class="tabs flex items-start side-tabs responsive-tabs clearfix">
    <ul class="tab-nav clearfix">
      <li
        class="cursor-pointer"
        v-on:click="toggleTabs(1)"
        v-bind:class="{
          'bg-white': openTab !== 1,
          'bg-transparent': openTab === 1,
        }"
        v-if="activePayTab && marketOption == 'zero'"
      >
        <img
          v-if="openTab === 1"
          src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/mpesa1.jpg"
        />
        <img
          v-if="openTab !== 1"
          src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/mpesa1.jpg"
        />
      </li>
      <li
        class="cursor-pointer"
        v-on:click="toggleTabs(2)"
        v-bind:class="{
          'bg-white': openTab !== 2,
          'bg-transparent': openTab === 2,
        }"
        v-if="
          activePayTab &&
          marketOption == 'zero' &&
          this.currencyConfig.country_code == 'KE'
        "
      >
        <img
          v-if="openTab === 2"
          src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/airtel1.jpg"
        />
        <img
          v-if="openTab !== 2"
          src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/airtel1.jpg"
        />
      </li>
      <li
        class="cursor-pointer"
        v-on:click="toggleTabs(3)"
        v-bind:class="{
          'bg-white': openTab !== 3,
          'bg-transparent': openTab === 3,
        }"
        v-if="
          activePayTab &&
          marketOption == 'zero' &&
          this.currencyConfig.country_code == 'KE'
        "
      >
        <img
          v-if="openTab === 3"
          src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/equitel1.jpg"
        />
        <img
          v-if="openTab !== 3"
          src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/equitel1.jpg"
        />
      </li>
      <li
        class="cursor-pointer"
        v-on:click="toggleTabs(10)"
        v-bind:class="{
          'bg-white': openTab !== 10,
          'bg-transparent': openTab === 10,
        }"
        v-if="
          activePayTab && this.ipay_currency_codes.includes(this.myCurrency)
        "
      >
        <img v-if="openTab === 10" src="@/assets/images/ipay.png" />
        <img v-if="openTab !== 10" src="@/assets/images/ipay.png" />
      </li>
      <li
        class="cursor-pointer"
        v-on:click="toggleTabs(5)"
        v-bind:class="{
          'bg-white': openTab !== 5,
          'bg-transparent': openTab === 5,
        }"
        v-if="activePayTab && showFlutter && marketOption != 'card'"
      >
        <img
          v-if="openTab === 5"
          src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/flutterwave1.jpg"
        />
        <img
          v-if="openTab !== 5"
          src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/flutterwave1.jpg"
        />
      </li>
      <li
        class="cursor-pointer"
        v-on:click="toggleTabs(8)"
        v-bind:class="{
          'bg-white': openTab !== 8,
          'bg-transparent': openTab === 8,
        }"
        v-if="myCurrency == 'XOFFF'"
      >
        <img
          src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto:eco,w_194,h_68/v1665038268/web/wave_2.png"
        />
      </li>
      <li
        class="cursor-pointer"
        v-on:click="toggleTabs(6)"
        v-bind:class="{
          'bg-white': openTab !== 6,
          'bg-transparent': openTab === 6,
        }"
        v-if="(activePayTab || marketOption == 'card') && myCurrency != 'NGN'"
      >
        <img
          v-if="openTab === 6"
          src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/visa1.jpg"
        />
        <img
          v-if="openTab !== 6"
          src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/visa1.jpg"
        />
      </li>
      <li
        class="cursor-pointer"
        v-on:click="toggleTabs(7)"
        v-bind:class="{
          'bg-white': openTab !== 7,
          'bg-transparent': openTab === 7,
        }"
        v-if="activePayTab && myCurrency != 'NGN'"
      >
        <img
          v-if="openTab === 7"
          src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/paypal1.jpg"
        />
        <img
          v-if="openTab !== 7"
          src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/paypal1.jpg"
        />
      </li>
    </ul>
    <div class="tab-container">
      <div
        class="tab-content clearfix"
        v-bind:class="{
          hidden: openTab !== 1,
          block: openTab === 1,
        }"
        v-if="activePayTab"
      >
        <div class="payment-method-content">
          <div class="payment-method-inner">
            <img
              src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/1200px-M-PESA_LOGO-01.svg.png"
              class="w-44 mx-auto"
            />
            <div class="payment-description-wrapper">
              <form @submit.prevent="onSubmit">
                <div class="switch-field">
                  <input
                    type="radio"
                    id="radio-one"
                    name="switch-one"
                    checked
                    @change="primerUpdate($event)"
                    :value="primer"
                  />
                  <label for="radio-one">
                    <number
                      :to="primer"
                      :duration="0"
                      :format="theFormat"
                      v-if="primer >= 200"
                    ></number>
                    <number
                      :to="200"
                      :duration="0"
                      :format="theFormat"
                      v-else
                    ></number>
                  </label>
                  <input
                    type="radio"
                    id="radio-two"
                    name="switch-one"
                    @change="primerUpdate($event)"
                    :value="primer_b"
                  />
                  <label for="radio-two">
                    <number
                      :to="primer_b"
                      :duration="0"
                      :format="theFormat"
                    ></number>
                  </label>
                  <input
                    type="radio"
                    id="radio-three"
                    name="switch-one"
                    :value="primer_c"
                    @change="primerUpdate($event)"
                  />
                  <label for="radio-three">
                    <number
                      :to="primer_c"
                      :duration="0"
                      :format="theFormat"
                    ></number>
                  </label>
                </div>
                <div
                  class="form-group unit-input"
                  :class="{ error: v$.form_m.mpesa_amount.$errors.length }"
                >
                  <span class="unit-input__prepend">KES</span>
                  <input
                    type="number"
                    class="form-control unit-input__input"
                    placeholder="Enter Amount in KES"
                    name="mpesa_amount"
                    id="mpesa_amount"
                    v-model="v$.form_m.mpesa_amount.$model"
                  />
                  <div
                    class="input-errors"
                    v-for="(error, index) of v$.form_m.mpesa_amount.$errors"
                    :key="index"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
                <input
                  id="donationAmount"
                  name="donationAmount"
                  :value="v$.form_m.mpesa_amount.$model"
                  type="hidden"
                  autocomplete="off"
                />
                <!-- Submit Button -->
                <div class="buttons-w">
                  <button
                    id="show-modal"
                    @click="tillAmount()"
                    :disabled="v$.form_m.$invalid"
                    class="button"
                  >
                    Donate Now
                  </button>
                </div>
                <Teleport to="body">
                  <!-- use the modal component, pass in the prop -->
                  <modal :show="showModal" @close="showModal = false">
                    <template #header>
                      <div>
                        <h3>{{ campaign_name }}</h3>
                      </div>
                    </template>
                    <template #body>
                      <h4>
                        KES {{ tillCheckoutAmount.toLocaleString() }} M-Pesa
                        Donation
                      </h4>
                      <ul>
                        <li>Fill the form below, Click Donate</li>
                        <li>Enter M-Pesa PIN when prompted on your phone</li>
                        <li>
                          You'll receive receipts from M-Pesa, and M-Changa
                          (22231)
                        </li>
                      </ul>
                      <form
                        method="post"
                        id="mpesa_form"
                        name="mpesa_form"
                        @submit.prevent="tillCheckout"
                      >
                        <div class="form-group">
                          <legend>M-Pesa Phone Number</legend>
                          <input
                            type="number"
                            class="form-control"
                            placeholder="Enter a valid M-Pesa Phone Number"
                            name="mpesa_phone"
                            id="mpesa_phone"
                            AUTOCOMPLETE="OFF"
                          />
                          <input
                            id="tillCheckoutAmount"
                            name="tillCheckoutAmount"
                            :value="tillCheckoutAmount"
                            type="hidden"
                            readonly
                            autocomplete="off"
                          />
                          <input
                            id="tillCheckoutAccount"
                            name="tillCheckoutAccount"
                            :value="accountNumber"
                            type="hidden"
                            readonly
                            autocomplete="off"
                          />
                        </div>
                        <div class="form-group">
                          <legend>Full Name</legend>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter your name"
                            name="mpesa_name"
                            id="mpesa_name"
                            AUTOCOMPLETE="OFF"
                            minlength="5"
                          />
                        </div>
                        <div class="form-group">
                          <legend>Goodwill Message</legend>
                          <textarea
                            cols="7"
                            rows="3"
                            class="form-control"
                            id="goodwill"
                            name="goodwill"
                            placeholder="Optional goodwill message to fundraiser"
                          ></textarea>
                        </div>
                        <div
                          id="till_error"
                          class="error-msg"
                          v-show="till_error_show"
                        >
                          {{ till_error }}
                        </div>
                        <div
                          id="till_success"
                          class="success"
                          v-show="till_success_show"
                        >
                          {{ till_success }}
                        </div>
                        <div>
                          <button
                            id="tillPush"
                            style="
                              width: 100%;
                              background-color: #75ac12;
                              font-weight: 600;
                              font-size: 24px;
                              color: white;
                              border-radius: 65px;
                            "
                            class="button"
                            @click="tillCheckout()"
                            :disabled="isDisabled"
                          >
                            Donate
                          </button>
                        </div>
                      </form>
                    </template>
                    <template #footer>
                      &copy; M-Changa {{ currentYear }}
                    </template>
                  </modal>
                </Teleport>
              </form>
              <div class="payment-text">
                <span>DIAL: *483*57*{{ accountNumber }}#</span>
                <span>PAYBILL: {{ paybill }}</span>
                <span v-if="champion_code">ACCOUNT: {{ champion_code }}</span>
                <span v-else-if="synonym != ''">ACCOUNT: {{ synonym }}</span>
                <span v-else>ACCOUNT: {{ accountNumber }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-content clearfix"
        v-bind:class="{
          hidden: openTab !== 2,
          block: openTab === 2,
        }"
      >
        <div class="payment-method-content">
          <div class="payment-method-inner">
            <img
              src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/Airtel_Uganda-Logo.wine.png"
              class="w-44 mx-auto"
            />
            <div class="payment-description-wrapper">
              <div class="payment-text">
                <span>Business Name: {{ businessName }}</span>
                <span v-if="champion_code"
                  >Account Number: {{ champion_code }}</span
                >
                <span v-else>Reference: {{ accountNumber }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-content clearfix"
        v-bind:class="{
          hidden: openTab !== 3,
          block: openTab === 3,
        }"
      >
        <div class="payment-method-content">
          <div class="payment-method-inner">
            <img
              src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/Equitel_Kenya_Logo.png"
              class="w-44 mx-auto"
            />
            <div class="payment-description-wrapper">
              <div class="payment-text">
                <span>Business Number: {{ paybill }}</span>
                <span v-if="champion_code"
                  >Account Number: {{ champion_code }}</span
                >
                <span v-else>Account Number: {{ accountNumber }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-content clearfix"
        v-bind:class="{
          hidden: openTab !== 880,
          block: openTab === 8,
        }"
      >
        <div class="payment-method-content">
          <div class="payment-method-inner">
            <img src="@/assets/images/wave.png" class="w-40 mx-auto" />
            <div class="payment-description-wrapper">
              <div class="payment-text">
                <span>Envoyez votre don à</span>
                <span>Téléphone: {{ this.xaf_msisdn }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-content clearfix"
        v-if="isFlutterwave"
        v-bind:class="{
          hidden: openTab !== 5,
          block: openTab === 5,
        }"
      >
        <div class="payment-method-content">
          <div class="payment-method-inner">
            <img
              src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/flutterwave-logo-vector.png"
              class="w-56 mx-auto"
            />
            <div class="payment-description-wrapper">
              <!-- Submit Button -->
              <div class="buttons-w">
                <button
                  id="show-modal"
                  @click="flutterModal = true"
                  class="button mb-3"
                >
                  Donate Now
                </button>
              </div>
              <Teleport to="body">
                <!-- use the modal component, pass in the prop -->
                <modal :show="flutterModal" @close="flutterModal = false">
                  <template #header>
                    <div>
                      <h3>{{ campaign_name }}</h3>
                    </div>
                  </template>
                  <template #body>
                    <ul>
                      <li>Fill the form below, and Click Donate</li>
                      <li>
                        Follow the steps & complete checkout process with your
                        mobile money provider
                      </li>
                      <li>
                        You'll receive one receipt from Flutterwave, and one
                        from M-CHANGA
                      </li>
                    </ul>
                    <form
                      method="post"
                      id="flutter_form"
                      name="flutter_form"
                      @submit.prevent="flutterCheckout(currencyConfig)"
                    >
                      <div class="form-group unit-input">
                        <span
                          class="unit-input__prepend"
                          v-if="currencyConfig.local_code != ''"
                          >Phone:</span
                        >
                        <span class="unit-input__prepend" v-else>Phone:</span>
                        <input
                          type="number"
                          class="form-control unit-input__input"
                          :placeholder="
                            'Your phone number e.g. ' +
                            this.local_country_codes[myCountryCode] +
                            '712345678'
                          "
                          name="flutter_phone"
                          id="flutter_phone"
                          AUTOCOMPLETE="OFF"
                          required
                          minLength="12"
                        />
                      </div>
                      <div class="form-group unit-input">
                        <span class="unit-input__prepend"
                          >Amount({{ this.myCurrency }}):</span
                        >
                        <input
                          type="number"
                          class="form-control unit-input__input"
                          :placeholder="
                            'Amount to donate (' + this.myCurrency + ')'
                          "
                          name="flutter_amount"
                          id="flutter_amount"
                          AUTOCOMPLETE="OFF"
                          required
                          min="10"
                        />
                      </div>
                      <div class="form-group unit-input">
                        <span class="unit-input__prepend">Name:</span>
                        <input
                          type="text"
                          class="form-control unit-input__input"
                          placeholder="Your full name"
                          name="flutter_name"
                          id="flutter_name"
                          AUTOCOMPLETE="OFF"
                          required
                        />
                      </div>
                      <div class="form-group unit-input">
                        <span class="unit-input__prepend">Email:</span>
                        <input
                          type="email"
                          class="form-control unit-input__input"
                          placeholder="Enter a valid email address"
                          name="flutter_email"
                          id="flutter_email"
                          AUTOCOMPLETE="OFF"
                          required
                        />
                        <input
                          id="flutter_account"
                          name="flutter_account"
                          :value="accountNumber"
                          type="hidden"
                          readonly
                          autocomplete="off"
                        />
                      </div>
                      <div
                        id="flutter_error"
                        class="error-msg"
                        v-show="flutter_error_show"
                      >
                        {{ flutter_error }}
                      </div>
                      <div
                        id="flutter_success"
                        class="success"
                        v-show="flutter_success_show"
                      >
                        {{ flutter_success }}
                      </div>
                      <div>
                        <button
                          style="
                            width: 100%;
                            background-color: #75ac12;
                            font-weight: 600;
                            font-size: 24px;
                            color: white;
                            border-radius: 65px;
                          "
                          class="button"
                          @click="flutterCheckout(currencyConfig)"
                        >
                          Donate
                        </button>
                      </div>
                    </form>
                  </template>
                  <template #footer>
                    &copy; M-Changa {{ currentYear }}
                  </template>
                </modal>
              </Teleport>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-content clearfix"
        v-bind:class="{
          hidden: openTab !== 6,
          block: openTab === 6,
        }"
      >
        <div class="payment-method-content">
          <div class="payment-method-inner">
            <img
              src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/visa-logo-png-2026.png"
              class="w-44 mx-auto"
            />
            <div class="payment-description-wrapper">
              <div id="stripe_init">
                <form @submit.prevent="onSubmit" id="stripe_frm">
                  <!-- Stripe Donation -->
                  <div class="form-group unit-input">
                    <span class="unit-input__prepend"
                      >Amount({{ currencyConfig.currency_code }})</span
                    >
                    <input
                      type="number"
                      class="form-control unit-input__input"
                      :placeholder="
                        'Enter Amount in ' + currencyConfig.currency_code
                      "
                      name="stripe_amount"
                      id="stripe_amount"
                      :min="currencyConfig.min_val"
                      step="1"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      title="Numbers only"
                    />
                    <input
                      type="hidden"
                      name="mycurrency"
                      id="mycurrency"
                      :value="local_currency"
                      readonly
                    />
                  </div>
                  <div>
                    <p v-if="stripe_err" class="error-msg">
                      {{ this.stripe_err_msg }}
                    </p>
                  </div>
                  <!-- Submit Button -->
                  <div class="buttons-w">
                    <button
                      class="btn btn-primary"
                      id="checkout-button"
                      v-on:click="stripeCheckout(currencyConfig)"
                    >
                      Donate
                    </button>
                  </div>
                </form>
                <div class="center">
                  <p style="text-align: center !important; font-size: smaller">
                    Disclaimer Your donation will show up on your statement as
                    "Changa Labs", an M-Changa affiliate.
                  </p>
                </div>
              </div>
              <div id="checkout">
                <!-- Checkout will insert the payment form here -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-content clearfix"
        v-bind:class="{
          hidden: openTab !== 7,
          block: openTab === 7,
        }"
      >
        <div class="payment-method-content">
          <div class="payment-method-inner">
            <img
              src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/paypal-logo-png-2116.png"
              class="w-44 mx-auto"
            />
            <div class="payment-description-wrapper">
              <form @submit.prevent="onSubmit">
                <div
                  class="form-group"
                  :class="{ error: v$.form_p.ppal_amount.$errors.length }"
                >
                  <div class="form-group unit-input">
                    <span class="unit-input__prepend">Amount(USD):</span>
                    <input
                      type="number"
                      class="form-control unit-input__input"
                      placeholder="Enter Amount in USD"
                      name="ppal_amount"
                      id="ppal_amount"
                      min="10"
                      step="1"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      title="Numbers only"
                    />
                    <input
                      name="cc_name"
                      id="cc_name"
                      :value="campaign_name"
                      type="hidden"
                      readonly
                    />
                    <input
                      name="cc_cid"
                      id="cc_cid"
                      :value="campaign_id"
                      type="hidden"
                      readonly
                    />
                  </div>
                  <div id="ppal_err" class="error-msg"></div>
                  <div id="ppal_success" class="success"></div>
                </div>
                <!-- Submit Button -->
                <div class="buttons-w">
                  <div id="paypal-button-container"></div>
                  <div id="payments-sdk__contingency-lightbox"></div>
                </div>
              </form>
              <div class="center">
                <p>Your payment info is stored securely. Learn more.</p>
                <p style="text-align: center !important">
                  Disclaimer Your donation will show up on your statement as
                  "Changa Labs", an M-Changa affiliate.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-content clearfix"
        v-bind:class="{
          hidden: openTab !== 999,
          block: openTab === 9999,
        }"
      >
        <div class="payment-method-content">
          <div class="payment-method-inner">
            <img src="@/assets/images/triplea-main.png" class="w-56 mx-auto" />
            <div class="payment-description-wrapper">
              <!-- Submit Button -->
              <div class="buttons-w">
                <button
                  id="show-modal"
                  @click="trippleModal = true"
                  class="button mb-3"
                >
                  Donate Now
                </button>
              </div>
              <Teleport to="body">
                <!-- use the modal component, pass in the prop -->
                <modal :show="trippleModal" @close="trippleModal = false">
                  <template #header>
                    <div>
                      <h3>{{ campaign_name }}</h3>
                    </div>
                  </template>
                  <template #body>
                    <ul>
                      <li>Fill the form below, and Click Donate</li>
                      <li>
                        Follow the steps & complete checkout process from your
                        bitcoin account
                      </li>
                    </ul>
                    <form
                      method="post"
                      id="tripple_form"
                      name="tripple_form"
                      @submit.prevent=""
                    >
                      <div class="form-group unit-input">
                        <span class="unit-input__prepend"
                          >Phone(+{{ currencyConfig.phone_code }}):</span
                        >
                        <input
                          type="number"
                          class="form-control unit-input__input"
                          placeholder="Enter a valid Phone Number"
                          name="tripple_phone"
                          id="tripple_phone"
                          AUTOCOMPLETE="OFF"
                          required
                          minLength="10"
                        />
                      </div>
                      <div class="form-group unit-input">
                        <span class="unit-input__prepend"
                          >Amount({{ currencyConfig.currency_code }}):</span
                        >
                        <input
                          type="number"
                          class="form-control unit-input__input"
                          :placeholder="
                            'Enter amount to donate (' +
                            currencyConfig.currency_code +
                            ')'
                          "
                          name="tripple_amount"
                          id="tripple_amount"
                          AUTOCOMPLETE="OFF"
                          required
                          min="10"
                        />
                      </div>
                      <div class="form-group unit-input">
                        <span class="unit-input__prepend">Name:</span>
                        <input
                          type="text"
                          class="form-control unit-input__input"
                          placeholder="Enter your full name"
                          name="tripple_name"
                          id="tripple_name"
                          AUTOCOMPLETE="OFF"
                          required
                        />
                      </div>
                      <div class="form-group unit-input">
                        <span class="unit-input__prepend">Email:</span>
                        <input
                          type="email"
                          class="form-control unit-input__input"
                          placeholder="Enter a valid email address"
                          name="tripple_email"
                          id="tripple_email"
                          AUTOCOMPLETE="OFF"
                          required
                        />
                        <input
                          id="tripple_account"
                          name="tripple_account"
                          :value="accountNumber"
                          type="hidden"
                          readonly
                          autocomplete="off"
                        />
                      </div>
                      <div
                        id="tripple_error"
                        class="error-msg"
                        v-show="tripple_error"
                      >
                        {{ tripple_error }}
                      </div>
                      <div
                        id="tripple_success"
                        class="success"
                        v-show="tripple_success"
                      >
                        {{ tripple_success }}
                      </div>
                      <div>
                        <button
                          style="
                            width: 100%;
                            background-color: #75ac12;
                            font-weight: 600;
                            font-size: 24px;
                            color: white;
                            border-radius: 65px;
                          "
                          class="button"
                          @click="trippleCheckout(currencyConfig)"
                        >
                          Donate
                        </button>
                      </div>
                    </form>
                  </template>
                  <template #footer>
                    &copy; M-Changa {{ currentYear }}
                  </template>
                </modal>
              </Teleport>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-content clearfix"
        v-bind:class="{
          hidden: openTab !== 10,
          block: openTab === 10,
        }"
      >
        <div class="payment-method-content">
          <div class="payment-method-inner">
            <img src="@/assets/images/ipay-2.png" class="mx-auto" />
            <div class="payment-description-wrapper">
              <!-- Submit Button -->
              <div class="buttons-w">
                <button
                  id="show-modal"
                  @click="ipayModal = true"
                  class="button mb-3"
                >
                  Donate Now
                </button>
              </div>
              <Teleport to="body">
                <!-- use the modal component, pass in the prop -->
                <modal :show="ipayModal" @close="ipayModal = false">
                  <template #header>
                    <div>
                      <h3>{{ campaign_name }}</h3>
                    </div>
                  </template>
                  <template #body>
                    <ul>
                      <li>Fill the form below, and Click Donate</li>
                      <li>Follow the steps & complete checkout process</li>
                    </ul>
                    <form
                      method="post"
                      id="tripple_form"
                      name="tripple_form"
                      @submit.prevent=""
                    >
                      <div class="form-group unit-input">
                        <span
                          class="unit-input__prepend"
                          v-if="currencyConfig.local_code != ''"
                          >Phone:</span
                        >
                        <span class="unit-input__prepend" v-else>Phone:</span>
                        <input
                          type="number"
                          class="form-control unit-input__input"
                          :placeholder="
                            'Your phone number e.g. ' +
                            this.local_country_codes[myCountryCode] +
                            '712345678'
                          "
                          name="ipay_phone"
                          id="ipay_phone"
                          AUTOCOMPLETE="OFF"
                          required
                          minLength="10"
                        />
                      </div>
                      <div class="form-group unit-input">
                        <span class="unit-input__prepend"
                          >Amount({{ this.myCurrency }}):</span
                        >
                        <input
                          type="number"
                          class="form-control unit-input__input"
                          :placeholder="
                            'Amount to donate (' + this.myCurrency + ')'
                          "
                          name="ipay_amount"
                          id="ipay_amount"
                          AUTOCOMPLETE="OFF"
                          required
                          min="10"
                        />
                      </div>
                      <div class="form-group unit-input">
                        <span class="unit-input__prepend">Name:</span>
                        <input
                          type="text"
                          class="form-control unit-input__input"
                          placeholder="Your full name"
                          name="ipay_name"
                          id="ipay_name"
                          AUTOCOMPLETE="OFF"
                          required
                        />
                      </div>
                      <div class="form-group unit-input">
                        <span class="unit-input__prepend">Email:</span>
                        <input
                          type="email"
                          class="form-control unit-input__input"
                          placeholder="Your email address"
                          name="ipay_email"
                          id="ipay_email"
                          AUTOCOMPLETE="OFF"
                          required
                        />
                        <input
                          id="ipay_account"
                          name="ipay_account"
                          :value="accountNumber"
                          type="hidden"
                          readonly
                          autocomplete="off"
                        />
                      </div>
                      <div
                        id="ipay_error"
                        class="error-msg"
                        v-show="ipay_error"
                      >
                        {{ ipay_error }}
                      </div>
                      <div
                        id="ipay_success"
                        class="success"
                        v-show="ipay_success"
                      >
                        {{ ipay_success }}
                      </div>
                      <div>
                        <button
                          style="
                            width: 100%;
                            background-color: #75ac12;
                            font-weight: 600;
                            font-size: 24px;
                            color: white;
                            border-radius: 65px;
                          "
                          class="button"
                          @click="ipayCheckout(currencyConfig)"
                        >
                          Donate
                        </button>
                      </div>
                    </form>
                  </template>
                  <template #footer>
                    &copy; M-Changa {{ currentYear }}
                  </template>
                </modal>
              </Teleport>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import {
  required,
  helpers,
  minValue,
  maxValue,
  between,
} from '@vuelidate/validators';
import { loadScript } from '@paypal/paypal-js';
import Modal from './DonationModal.vue';

export default {
  components: {
    Modal,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      openTab: 1,
      form: {
        visa_amount: '',
      },
      form_p: {
        ppal_amount: '',
      },
      form_m: {
        mpesa_amount: '',
      },
      is_ipay_tz_loaded: false,
      stripe_err: false,
      stripe_err_msg: '',
      is_ppal_loaded: false,
      ppal_err: '',
      ppal_success: '',
      showModal: false,
      flutterModal: false,
      tillCheckoutAmount: 0,
      currentYear: new Date().getFullYear(),
      till_error: '',
      till_error_show: false,
      till_success: '',
      till_success_show: false,
      flutter_error: '',
      flutter_error_show: false,
      flutter_success: '',
      flutter_success_show: false,
      isDisabled: false,
      showFlutter: false,
      trippleModal: false,
      tripple_error: '',
      tripple_success: '',
      ipay_error: '',
      ipay_success: '',
      ipayModal: false,
    };
  },
  validations() {
    return {
      form: {
        visa_amount: {
          required,
          minValue: minValue(this.stripeMinAmount),
          maxValue: helpers.withMessage(
            ({ $params }) =>
              `Maximum value allowed  ${$params.stripeMinAmount}`,
            maxValue(this.stripeMinAmount),
          ),
        },
      },
      form_p: {
        ppal_amount: {
          required,
          minValue: helpers.withMessage('Minimum value is 13', minValue(13)),
          maxValue: maxValue(5000),
        },
      },
      form_m: {
        mpesa_amount: {
          between: helpers.withMessage('', between(10, 250000)),
        },
      },
      stripeMinAmount: 0,
    };
  },

  async created() {
    this.stripeAmount();

    // load ipay js sdk - TZ
    if (!this.is_ipay_tz_loaded && this.myCountryCode == 'TZ') {
      let ipayTZScript = document.createElement('script');
      ipayTZScript.setAttribute(
        'src',
        'https://www.tz.elipa.global/js_sdk/v1/tz-sdk-v1.js',
      );
      document.head.appendChild(ipayTZScript);
      this.is_ipay_tz_loaded = true;
    }

    // paypal buttons
    this.ppalLoader(this.currencyConfig);
    // flutterwave check
    if (this.marketOption != 'card') {
      if (this.isFlutterwave != 'no') {
        if (this.isFlutterwave) {
          this.openTab = 5;
          this.showFlutter = this.isFlutterwave;
        }
      } else if (this.isFlutterwave == 'no') {
        // start watching
        const unwatch = this.$watch('isFlutterwave', () => {
          if (this.isFlutterwave) {
            this.openTab = 5;
            this.showFlutter = this.isFlutterwave;
          }
          unwatch();
        });
      }
    }
    if (this.marketOption == 'zero') {
      // start watching
      const unwatch = this.$watch('marketOption', () => {
        if (this.marketOption == 'two') {
          this.openTab = 8;
          this.toggleTabs(8);
        }
        if (
          this.alt_country_code == 'US' ||
          this.currencyConfig.country_code != this.myCountryCode ||
          this.marketOption == 'card'
        ) {
          this.openTab = 6;
          this.toggleTabs(6);
        }
        unwatch();
      });
    }
    if (this.alt_country_code == '') {
      const unwatch = this.$watch('alt_country_code', () => {
        if (
          this.alt_country_code == 'US' ||
          this.currencyConfig.country_code !== this.myCountryCode ||
          this.marketOption == 'card'
        ) {
          this.openTab = 6;
          this.toggleTabs(6);
        }
      });
    } else {
      if (this.marketOption == 'two') {
        this.openTab = 8;
        this.toggleTabs(8);
      }
      if (
        this.alt_country_code == 'US' ||
        this.currencyConfig.country_code !== this.myCountryCode ||
        this.marketOption == 'card'
      ) {
        this.openTab = 6;
        this.toggleTabs(6);
      }
    }
  },

  methods: {
    toggleTabs(tabNumber) {
      this.openTab = tabNumber;
    },
    stripeAmount: async function () {
      this.stripeMinAmount = 1000;
    },
    // stripe checkout
    stripeCheckout: function (currencyConfig) {
      // Create an instance of the Stripe object with your publishable API key
      var checkoutButton = document.getElementById('checkout-button');
      checkoutButton.disabled = true;
      this.stripe_err_msg = '';
      this.stripe_err = false;
      var stripe_amount = document.getElementById('stripe_amount').value;
      const min_val = currencyConfig.min_amount;
      const max_val = currencyConfig.max_amount;
      var m_currency = currencyConfig.currency_code;
      if (stripe_amount == '' || parseInt(stripe_amount) < min_val) {
        checkoutButton.disabled = false;
        this.stripe_err = true;
        this.stripe_err_msg =
          'Minimum Amount: ' + m_currency + ' ' + min_val.toLocaleString('en');
        return 0;
      }
      if (parseInt(stripe_amount) > max_val) {
        checkoutButton.disabled = false;
        this.stripe_err = true;
        this.stripe_err_msg =
          'Maximum Amount: ' + m_currency + ' ' + max_val.toLocaleString('en');
        return 0;
      }
      checkoutButton.innerHTML = 'Processing.....';

      if (this.mclIFrame) {
        var success_url =
          this.baseURL + '/mcl-donation/' + this.accountNumber + '/success';
        var cancel_url = this.baseURL + '/mcl-donation/' + this.accountNumber;
      } else {
        var success_url =
          this.baseURL + '/fundraiser/' + this.accountNumber + '/success';
        var cancel_url = this.baseURL + '/fundraiser/' + this.accountNumber;
      }
      var stripeData = {
        campaign_name: this.campaign_name,
        campaign_id: this.campaign_id,
        currency: m_currency,
        country_code: currencyConfig.country_code,
        stripe_amount: document.getElementById('stripe_amount').value,
        ip: currencyConfig.ip_address,
        success_url: success_url,
        cancel_url: cancel_url,
      };

      fetch(
        process.env.VUE_APP_ROOT_API +
          '/service/donation/stripe-checkout-session',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(stripeData),
        },
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (session) {
          const stripeScript = document.createElement('script');
          stripeScript.src = 'https://js.stripe.com/v3/';
          stripeScript.onload = () => {
            // hide mcl form
            document.getElementById('stripe_init').style.display = 'none';
            console.error('STripe loaded');
            const { clientSecret } = session;
            const stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);

            // Initialize Checkout
            const checkout = stripe
              .initEmbeddedCheckout({
                clientSecret,
              })
              .then(function (resp) {
                // Mount Checkout
                resp.mount('#checkout');
              });
          };
          document.head.appendChild(stripeScript);
          return true;
        })
        .then(function (result) {
          // If redirectToCheckout fails due to a browser or network
          // error, you should display the localized error message to your
          // customer using error.message.
          if (result.error) {
            alert(result.error.message);
          }
        })
        .catch(function (error) {
          console.error('Error:', error);
        });
    },
    ppalLoader: function (currencyConfig) {
      if (!this.is_ppal_loaded) {
        loadScript({
          'client-id': process.env.VUE_APP_PAYPAL_KEY,
          currency: 'USD',
          'disable-funding': 'credit,card',
          components: 'buttons',
        })
          .then((paypal) => {
            paypal
              .Buttons({
                style: { shape: 'pill', size: 'responsive' },
                commit: false,

                // Call your server to set up the transaction
                // Set up the transaction
                createOrder: function () {
                  var ppalData = {
                    campaign_name: document.getElementById('cc_name').value,
                    campaign_id: document.getElementById('cc_cid').value,
                    currency: 'USD',
                    country_code: currencyConfig.country_code,
                    amount: document.getElementById('ppal_amount').value,
                    ip: currencyConfig.ip_address,
                  };
                  return fetch(
                    process.env.VUE_APP_ROOT_API +
                      '/service/donation/ppal_order',
                    {
                      method: 'POST',
                      headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify(ppalData),
                    },
                  )
                    .then(function (res) {
                      return res.json();
                    })
                    .then(function (res) {
                      if (res.error) {
                        document.getElementById('ppal_success').style.display =
                          'none';
                        document.getElementById('ppal_err').innerHTML =
                          res.error;
                        document.getElementById('ppal_err').style.display =
                          'block';
                        return res.error;
                      }
                      document.getElementById('ppal_err').style.display =
                        'none';
                      document.getElementById('ppal_success').style.display =
                        'block';
                      document.getElementById('ppal_success').innerHTML =
                        'PayPal: Waiting for approval';
                      return res.id;
                    });
                },
                onCancel: function (data) {
                  // Show a cancel page, or return to cart
                  location.reload();
                },
                onApprove: function (data, actions) {
                  // This function captures the funds from the transaction
                  return actions.order.capture().then(function (details) {
                    // This function shows a transaction success message to your buyer
                    document.getElementById('ppal_success').innerHTML =
                      'Success: Thanks for your donation.';
                    return fetch(
                      process.env.VUE_APP_ROOT_API +
                        '/service/donation/ppal_confirmOrder',
                      {
                        method: 'POST',
                        headers: {
                          Accept: 'application/json',
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(details),
                      },
                    ).then(function (res) {
                      window.setTimeout(function () {
                        location.reload();
                      }, 3000);
                    });
                  });
                },
              })
              .render('#paypal-button-container')
              .catch((error) => {
                console.error('failed to render the PayPal Buttons', error);
              });
          })
          .catch((error) => {
            console.error('failed to load the PayPal JS SDK script', error);
          });
        this.is_ppal_loaded = true;
      }
    },
    tillAmount: function () {
      const myAmount = parseInt(
        document.getElementById('donationAmount').value,
      );
      if (myAmount >= 10) {
        this.tillCheckoutAmount = myAmount;
      } else {
        this.tillCheckoutAmount = this.mpesaCheckoutAmout;
      }

      this.showModal = true;
    },
    primerUpdate: function (event) {
      var data = event.target.value;
      document.getElementById('donationAmount').value = data;
      document.getElementById('mpesa_amount').value = data;
      return true;
    },
    validatePhone: function (phone, location = '') {
      const codes = this.allowed_country_codes;
      var errorMessage = '';
      if (parseInt(phone.length) < 10) {
        errorMessage +=
          'Provide phone number in correct international format\n';
        return errorMessage;
      }
      if (location == 'all') {
        var intl_code = this.currencyConfig.phone_code;
        if (phone.substring(0, intl_code.toString().length) != intl_code) {
          errorMessage += 'Phone must be in international format, NOT allowed';
          return errorMessage;
        }
      } else if (location == 'omarket') {
        let myCountryCode = this.myCountryCode;
        var intl_code = this.local_country_codes[myCountryCode];
        if (phone.substring(0, intl_code.toString().length) != intl_code) {
          errorMessage += 'Phone must be in specified international format!';
          return errorMessage;
        }
      } else {
        const allowed_code = codes.includes(phone.substring(0, 3));
        if (allowed_code == false) {
          errorMessage += 'Phone number NOT allowed';
          return errorMessage;
        }
      }

      return 'valid';
    },
    tillCheckout: function () {
      this.isDisabled = true;
      var phone = document.getElementById('mpesa_phone').value;
      if (parseInt(phone.length) == 10) {
        phone = '254' + phone.slice(-9);
      }
      const validPhone = this.validatePhone(phone, 'KE');
      if (validPhone != 'valid') {
        this.isDisabled = false;
        this.till_error = validPhone;
        this.till_error_show = true;
        setTimeout(() => (this.till_error_show = false), 3000);
        return -1;
      }
      const amount = parseInt(
        document.getElementById('tillCheckoutAmount').value,
      );
      if (amount < 10) {
        this.isDisabled = false;
        this.till_error = 'Invalid amount.';
        this.till_error_show = true;
        setTimeout(() => (this.till_error_show = false), 3000);
        return -1;
      }
      if (document.getElementById('mpesa_name').value == '') {
        this.isDisabled = false;
        this.till_error = 'Name is required';
        this.till_error_show = true;
        setTimeout(() => (this.till_error_show = false), 3000);
        return -1;
      }
      var tillData = {
        campaign_name: this.campaign_name,
        TransactionID: document.getElementById('tillCheckoutAccount').value,
        campaign_id: this.campaign_id,
        PhoneNumber: phone,
        ContributorName: document.getElementById('mpesa_name').value,
        currency: this.local_currency,
        country_code: this.country_code,
        Amount: amount,
        ip: this.local_ip,
        callback_url: this.apiURL + '/service/donation/till-checkout',
        cust_message: document.getElementById('goodwill').value,
        Source: 'WEB',
        primedAverage: this.primer,
        referralCode: this.champion_code,
      };
      this.till_success_show = true;
      this.till_success = 'Processing M-Pesa request...';

      fetch(this.apiURL + '/service/donation/till-checkout', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(tillData),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.resp == 'error') {
            this.till_success_show = false;
            document.getElementById('till_error').innerHTML = data.message;
            this.till_error_show = true;
          } else {
            document.getElementById('till_success').innerHTML = data.message;
            this.till_success_show = true;
          }
          window.setTimeout(function () {
            location.reload();
          }, 1500);
        })
        .catch(function (error) {
          console.error('Error:', error);
        });
    },
    flutterCheckout: function (currencyConfig) {
      this.isDisabled = true;
      this.flutter_error = '';
      this.flutter_error_show = false;
      var phone = document.getElementById('flutter_phone').value;
      const validPhone = this.validatePhone(phone, 'omarket');
      if (validPhone != 'valid') {
        this.isDisabled = false;
        this.flutter_error = validPhone;
        this.flutter_error_show = true;
        setTimeout(() => (this.flutter_error_show = false), 3000);
        return -1;
      }
      const amount = parseInt(document.getElementById('flutter_amount').value);
      if (
        isNaN(amount) ||
        amount < this.flutter_currency_min[this.myCurrency]
      ) {
        this.isDisabled = false;
        this.flutter_error =
          'Minimum amount - ' +
          this.myCurrency +
          ' ' +
          this.flutter_currency_min[this.myCurrency];
        this.flutter_error_show = true;
        setTimeout(() => (this.flutter_error_show = false), 3000);
        return -1;
      }
      var FW_Channel = '';
      let myCurrency = this.myCurrency;
      let c_code = this.myCountryCode;
      if (this.available_countries.includes(c_code)) {
        FW_Channel = 'mobilemoney' + c_code.toLowerCase();
      } else {
        this.isDisabled = false;
        this.flutter_error = 'Invalid Currency Channel - check ' + myCurrency;
        this.flutter_error_show = true;
        setTimeout(() => (this.flutter_error_show = false), 3000);
        return -1;
      }
      var ContributorName = document.getElementById('flutter_name').value;
      if (ContributorName == '' || ContributorName.length < 7) {
        this.isDisabled = false;
        this.flutter_error = 'Name is required!';
        this.flutter_error_show = true;
        setTimeout(() => (this.flutter_error_show = false), 3000);
        return -1;
      }
      var flutterData = {
        CampaignName: this.campaign_name,
        TransactionID: document.getElementById('flutter_account').value,
        Campaign_ID: this.campaign_id,
        PhoneNumber: phone,
        ContributorName: ContributorName,
        Currency: this.myCurrency,
        FW_Channel: FW_Channel,
        Amount: amount,
        Email: document.getElementById('flutter_email').value,
        callback_url: window.location.href + '/success',
      };
      this.flutter_success_show = true;
      this.flutter_success = 'Processing Donation request...';

      fetch(this.apiURL + '/service/donation/flutter-checkout', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(flutterData),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.link) {
            window.location.replace(data.link);
          }
        })
        .catch(function (error) {
          location.reload();
          console.error('Error:', error);
        });
    },
    trippleCheckout: function (currencyConfig) {
      this.isDisabled = true;
      this.tripple_error = '';
      this.tripple_success = '';
      var phone = document.getElementById('tripple_phone').value;
      phone.replace(/[^\d.-]+/g, '');
      const validPhone = this.validatePhone(phone, 'all');
      if (validPhone != 'valid') {
        this.isDisabled = false;
        this.tripple_error = validPhone;
        setTimeout(() => (this.tripple_error = ''), 5000);
        return -1;
      }

      const amount = parseInt(document.getElementById('tripple_amount').value);
      if (isNaN(amount)) {
        this.isDisabled = false;
        this.tripple_error = 'Minimum amount - 20';
        setTimeout(() => (this.tripple_error = ''), 5000);
        return -1;
      }

      var ContributorName = document.getElementById('tripple_name').value;
      if (ContributorName == '' || ContributorName.length < 7) {
        this.isDisabled = false;
        this.tripple_error = 'Full Name is required!';
        setTimeout(() => (this.tripple_error = ''), 5000);
        return -1;
      }
      var trippleData = {
        CampaignName: this.campaign_name,
        TransactionID: document.getElementById('tripple_account').value,
        Campaign_ID: this.campaign_id,
        payer_phone: phone,
        payer_name: ContributorName,
        Currency: currencyConfig.currency_code,
        Amount: amount,
        payer_email: document.getElementById('tripple_email').value,
      };
      this.tripple_success = 'Processing Donation request...';

      fetch(this.apiURL + '/service/donation/triple-checkout', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(trippleData),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success) {
            document.getElementById('tripple_success').innerHTML = data.message;
            window.location.replace(data.link);
          } else {
            document.getElementById('tripple_success').style.display = 'none';
            document.getElementById('tripple_error').style.display = 'block';
            document.getElementById('tripple_error').innerHTML = data.message;
            return -1;
          }
        })
        .catch(function (error) {
          // location.reload();
          document.getElementById('tripple_error').innerHTML =
            'Error. Try again';
          console.error('Error:', data.message);
        });
    },
    ipayCheckout: function (currencyConfig) {
      if (!this.is_ipay_tz_loaded && this.myCurrency == 'TZS') {
        let ipayTZScript = document.createElement('script');
        ipayTZScript.setAttribute(
          'src',
          'https://www.tz.elipa.global/js_sdk/v1/tz-sdk-v1.js',
        );
        document.head.appendChild(ipayTZScript);
        this.is_ipay_tz_loaded = true;
      }
      if (!this.is_ipay_zm_loaded && this.myCurrency == 'ZMW') {
        let ipayZMScript = document.createElement('script');
        ipayZMScript.setAttribute(
          'src',
          'https://www.zm.elipa.global/js_sdk/v2/v4/generate/sid/zm/zm-sdk.js',
        );
        document.head.appendChild(ipayZMScript);
        this.is_ipay_zm_loaded = true;
      }

      this.isDisabled = true;
      this.ipay_error = '';
      this.ipay_success = '';
      var phone = document.getElementById('ipay_phone').value;
      phone.replace(/[^\d.-]+/g, '');
      const validPhone = this.validatePhone(phone, 'omarket');
      if (validPhone != 'valid') {
        this.isDisabled = false;
        this.ipay_error = validPhone;
        setTimeout(() => (this.ipay_error = ''), 5000);
        return -1;
      }

      const amount = parseInt(document.getElementById('ipay_amount').value);
      if (isNaN(amount) || amount < this.ipay_currency_min[this.myCurrency]) {
        this.isDisabled = false;
        this.ipay_error =
          'Minimum amount - ' +
          this.myCurrency +
          ' ' +
          this.ipay_currency_min[this.myCurrency];
        setTimeout(() => (this.ipay_error = ''), 5000);
        return -1;
      }

      var ContributorName = document.getElementById('ipay_name').value;
      if (ContributorName == '' || ContributorName.length < 7) {
        this.isDisabled = false;
        this.ipay_error = 'Full Name is required!';
        setTimeout(() => (this.ipay_error = ''), 5000);
        return -1;
      }
      const ipay_currencies = this.ipay_currency_codes;
      const ipay_vids = this.ipay_vids;
      const Currency = this.myCurrency;
      const Country = this.myCountryCode;
      const callBack_API =
        this.apiURL + '/service/donation/ipay-donation-callback';
      var ipayData = {
        CampaignName: this.campaign_name,
        TransactionID: document.getElementById('ipay_account').value,
        Campaign_ID: this.campaign_id,
        payer_phone: phone,
        payer_name: ContributorName,
        Currency: Currency,
        Amount: amount,
        payer_email: document.getElementById('ipay_email').value,
      };
      this.ipay_success = 'Processing Donation request...';

      fetch(this.apiURL + '/service/donation/ipay-donation', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(ipayData),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success) {
            if (Currency == 'UGX') {
              document.getElementById('ipay_success').innerHTML = data.message;
              window.location.replace(data.url);
            } else if (ipay_currencies.includes(Currency)) {
              console.error(Currency)
              let params = data.params;
              try {
                let elipatwo = new ELIPA({
                  MERCHANT_ID: ipay_vids[Currency],
                });
                console.error(elipatwo)
                let epay_data = {
                  amount: params.Amount,
                  email: params.payer_email,
                  order_id: data.order_id,
                  currency: params.Currency,
                  country: Country,
                  callback: function (callbackResponse) {
                    if (callbackResponse.status) {
                      // transaction_code
                      params.ipay_response = callbackResponse;
                      fetch(callBack_API, {
                        method: 'POST',
                        headers: {
                          Accept: 'application/json',
                          'Content-Type': 'application/json',
                        },

                        body: JSON.stringify(params),
                      })
                        .then(function (response) {
                          return response.json();
                        })
                        .then(function (data) {
                          console.error(data.status);
                          if (data.status == 'success') {
                            document.getElementById(
                              'ipay_success',
                            ).style.display = 'block';
                            document.getElementById(
                              'ipay_error',
                            ).style.display = 'none';
                            document.getElementById('ipay_success').innerHTML =
                              'Transaction Successful';
                            setTimeout(function () {
                              window.location.reload();
                            }, 5000);
                          } else {
                            document.getElementById(
                              'ipay_success',
                            ).style.display = 'none';
                            document.getElementById(
                              'ipay_error',
                            ).style.display = 'block';
                            document.getElementById('ipay_error').innerHTML =
                              data.message;
                          }
                        });
                    } else {
                      document.getElementById('ipay_success').style.display =
                        'none';
                      document.getElementById('ipay_error').style.display =
                        'block';
                      document.getElementById('ipay_error').innerHTML =
                        'Transaction Failed, please try again. IR007';
                      return -1;
                    }
                    // console.log("callback pressed", callbackResponse);
                  },
                };
                console.error(epay_data);
                elipatwo.start(epay_data);
              } catch (err) {
                console.error(err);
              }
            }
          } else {
            document.getElementById('ipay_success').style.display = 'none';
            document.getElementById('ipay_error').style.display = 'block';
            document.getElementById('ipay_error').innerHTML = data.message;
            return -1;
          }
        })
        .catch(function (error) {
          // location.reload();
          document.getElementById('ipay_error').innerHTML = 'Error. Try again';
          console.error('Error:', error.message);
        });
    },
  },
  props: {
    mpesaCheckoutAmout: {
      default: 200,
      type: Number,
    },
    primer: {
      default: 200,
      type: Number,
    },
    primer_b: {
      default: 150,
      type: Number,
    },
    primer_c: {
      default: 200,
      type: Number,
    },
    accountNumber: {
      default: 0,
      type: Number,
    },
    synonym: {
      default: '',
      type: String,
    },
    paybill: {
      default: '',
    },
    businessName: {
      default: '',
    },
    campaign_name: {
      default: '',
      type: String,
    },
    campaign_id: {
      default: '',
      type: String,
    },
    champion_code: {
      default: false,
      type: Boolean,
    },
    currencyConfig: {
      default: '',
    },
    step: {
      default: 8,
    },
    activePayTab: {
      default: 9,
    },
    isFlutterwave: {
      default: 'no',
    },
    marketOption: {
      default: 'zero',
    },
    myCurrency: {
      default: '',
    },
    myCountryCode: {
      default: '',
    },
    myIntPrefix: {
      default: '',
    },
    mclIFrame: {
      default: '',
    },
    alt_country_code: {
      default: 'KE',
    },
  },
};
</script>

<style scoped>
.payment-description-wrapper {
  margin-top: 15px;
}

.flogos {
  width: 53%;
}

button:disabled,
button[disabled] {
  border: 1px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
}

#ppal_err,
#ppal_success {
  display: none;
}

.error-msg {
  color: #d63301;
  background-color: #ffccba;
  text-align: center;
  padding: 5px;
}

.info {
  color: #00529b;
  background-color: #bde5f8;
  text-align: center;
  padding: 5px;
}

.success {
  color: #4f8a10;
  background-color: #dff2bf;
  text-align: center;
  padding: 5px;
}

#show-modal {
  text-align: center;
  vertical-align: middle;
  display: inline-block;
}

ul {
  list-style: disc;
}

.unit-input {
  border: #dfdfdf solid 1px;
  display: inline-flex;
  border-radius: 4px;
  overflow: hidden;
  font-family: sans-serif;
}

.unit-input__input {
  border: 0;
  padding: 0.5em;
  font-size: 1em;
  width: 100%;
  margin: 0;
  border-radius: 0;
}

.unit-input__prepend,
.unit-input__append {
  background: #f4f4f4;
  padding: 0.5em;
  border: #dfdfdf solid 0;
  flex-grow: 0;
}

.unit-input__prepend {
  border-right-width: 1px;
}

.payment-description-wrapper .input-group .form-control {
  padding-right: 0;
}

.unit-input {
  width: 100% !important;
}

@media (max-width: 767px) {
  legend {
    display: none;
  }

  .donation-payment-method .tab-container {
    padding: 20px !important;
  }
}
</style>
